<template>
  <div id="app">
    <div class="header container-fluid" v-if="headerVisibility">
      <div class="container">
        <div class="row header__row">
          <div
            class="header__column col-11 offset-1 d-flex flex-row justify-content-between align-items-center"
          >
            <img class="col-2 logo" alt="Алроса" src="@/assets/logo.svg" @click="$router.push({ name: 'Layouts' })" />
            <div class="header__nav-menu">
              <a
                  class="header__nav-link"
                  @click.prevent.stop="$router.push({ name: 'AddNew' })"
              >
                Создать письмо
              </a>
              <a
                  class="header__nav-link"
                  @click.prevent.stop="$router.push({ name: 'Archive' })"
              >
                Архив
              </a>
            </div>
            <div class="header__user-info" :title="`${user.first_name} ${user.last_name} ${department}`">
              {{ user.first_name }}
              {{ user.last_name }}
              {{ department }}
            </div>
            <div class="header__actions">
              <div class="header__actions-btns" v-if="showHeaderButtons">
                <div class="header__menu-wrapper">
                  <button
                    class="btn-menu btn-secondary-custom"
                    @click="toggleContextMenu()"
                  ></button>
                  <context-menu
                    v-if="showContextMenu"
                    @save-templates="emitTemplates"
                    @save-ready="emitSave"
                    @save-draft="emitDraft"
                    @save-download="emitDownload"
                    @save-copy="emitCopy"
                    @close="checkContextMenuLeave"
                  ></context-menu>
                </div>
                <button
                  class="btn-custom btn-secondary-custom btn-preview"
                  @click.prevent.stop="emitDraft"
                >
                  Предпросмотр
                </button>
              </div>
              <a class="header__actions-logout" @click.prevent.stop="logoutUser">Выйти</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view @show="showHeaderButtons = $event"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ContextMenu from "@/components/ContextMenu.vue";
export default {
  name: "App",
  data() {
    return {
      showHeaderButtons: false,
      showContextMenu: false,
    };
  },
  computed: {
    ...mapGetters(["headerVisibility", "user"]),
    department: function() {
      const department = this.$store.getters.user?.department;
      return department ? `(${department.name})` : '';
    },
  },
  components: {
    ContextMenu,
  },
  methods: {
    ...mapActions(["logout"]),
    logoutUser() {
      this.logout();
      this.$router.push({ path: "/" });
    },
    toggleContextMenu(show = true) {
      this.showContextMenu = show;
    },
    emitSave() {
      this.toggleContextMenu(false);
      this.$emit("save-post", {
        toDrafts: false,
        showModal: false,
        downloadAfter: false,
      });
    },
    emitTemplates() {
      this.toggleContextMenu(false);
      this.$emit("save-templates", {
        toDrafts: false,
        showModal: false,
        downloadAfter: false,
      });
    },
    emitDraft() {
      this.toggleContextMenu(false);
      this.$emit("save-post", {
        toDrafts: true,
        showModal: true,
        downloadAfter: false,
      });
    },
    emitCopy() {
      this.toggleContextMenu(false);
      this.$emit("save-copy", {
        toDrafts: true,
        showModal: false,
        downloadAfter: false,
      });
    },
    emitDownload() {
      this.toggleContextMenu(false);
      this.$emit("save-post", {
        toDrafts: true,
        showModal: false,
        downloadAfter: true,
      });
    },
    checkContextMenuLeave() {
      this.toggleContextMenu(false);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 10%;
  border-bottom: 1px solid #e3e9f2;
  &__row {
    padding: 0 8px;
  }

  &__column {
    flex-shrink: 0;
  }

  &__actions {
    margin-left: auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &-btns {
      flex-basis: 80%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      .btn-custom:first-of-type {
        margin-right: 1.5rem;
      }
    }
    &-logout {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__nav-menu {
    margin-right: 12px;
    flex-shrink: 0;
    white-space: nowrap;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__nav-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  &__user-info {
    margin-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &__menu-wrapper {
    position: relative;
  }
}
.logo {
  width: 139px;
  height: 20px;
  cursor: pointer;
}
.btn-save,
.btn-preview {
  height: 32px !important;
  font-size: 10px !important;
  line-height: 100% !important;
  width: 176px !important;
  margin-left: 20px;
}
.btn-menu {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1' cy='1' r='1' fill='black'/%3E%3Ccircle cx='7' cy='1' r='1' fill='black'/%3E%3Ccircle cx='13' cy='1' r='1' fill='black'/%3E%3C/svg%3E%0A") !important;
  background-repeat: no-repeat !important;
  background-size: 14px 2px !important;
  background-position: center !important;
  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='1' cy='1' r='1' fill='black'/%3E%3Ccircle cx='7' cy='1' r='1' fill='black'/%3E%3Ccircle cx='13' cy='1' r='1' fill='black'/%3E%3C/svg%3E%0A") !important;
    background-repeat: no-repeat !important;
    background-size: 14px 2px !important;
    background-position: center !important;
  }
}
</style>
